import React from 'react';
import { useEffect } from 'react';

function MaintenanceGeneral () {

  return (
    <>
      <div className="error_content_two text-center">
        <div className="error_img">
          <img className="p_absolute error_shap" src="/assets/img/404_bg.png" alt=""/>
            <div className="one wow clipInDown" data-wow-delay="1s"><img className="img_one" src="/assets/img/404_two.png" alt=""/></div>
            <div className="two wow clipInDown" data-wow-delay="1.5s"><img className="img_two" src="/assets/img/404_three.png" alt=""/></div>
            <div className="three wow clipInDown" data-wow-delay="1.8s"><img className="img_three" src="/assets/img/404_one.png" alt=""/></div>
        </div>
        <h2>Error. We can't find the item you're looking for.</h2>
        <p>This is not a normal situation. Go to our homepage or check out your report id, if problem persist contact us.</p>
        <a href="/" className="action_btn box_shadow_none"><i className="arrow_left mr-2"></i>Back to Home</a>
      </div>
    </>
  );
}

export default MaintenanceGeneral;