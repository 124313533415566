import React, { useState, useEffect } from "react";
import {
  Box,
  styled,
  ThemeProvider,
  createTheme,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Slider,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Collapse,
} from "@mui/material";
import { KeyboardArrowDown, Tune, ArrowBackIosNew } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";

const gemstoneNames = [
  "Amethyst",
  "Garnet",
  "Aquamarine",
  "Topaz",
  "Peridot",
  "Smoky quartz",
  "Opal",
  "Tourmaline",
  "Morganite",
  "Agate",
  "Citrine",
  "Quartz",
  "Moonstone",
  "Labradorite",
  "Tanzanite",
  "Iolite",
  "Rhodochrosite",
  "Cianite",
  "Emerald",
  "Ruby",
  "Sapphire",
  "Diamond",
  "Pearl",
  "Jade",
  "Coral",
  "Hematite",
  "Larimar",
  "Sultanite",
  "Andalusite",
  "Beryl",
  "Lapis lazuli",
  "Tiger's eye",
  "Sodalite",
  "Malachite",
  "Rutilated Quartz",
  "Dendritic Quartz",
  "Moissanite",
  "Milky Quartz",
  "Mother of pearl",
  "Howlite",
  "Amazonite",
  "Jasper",
  "Intaglio",
  "Red ganju seeds",
  "Snowflake obsidian",
  "Onyx",
];

const gemstoneCutNames = [
  "Round",
  "Oval",
  "Princess",
  "Cushion",
  "Emerald",
  "Asscher",
  "Marquise",
  "Radiant",
  "Pear",
  "Heart",
  "Straight Baguette",
  "Kite",
  "Lozenge",
  "Trillion",
  "Hexagonal",
  "Octagonal",
  "Shield",
  "Bullet",
  "Briolette",
  "Cabochon",
  "Checkerboard",
  "Tapered Baguette",
  "Half Moon",
  "Fancy",
  "Sphere",
  "Half-Sphere",
  "Free",
  "Rough",
  "Trapezoid",
  "Calf's Head",
  "Triangle",
  "Keystone",
  "Long Hexagonal",
  "Pentagonal",
  "Epaulette",
  "Tapered Pentagonal",
  "Cut-Cornet Triangle",
  "Long Octagonal",
  "Whistle",
  "Rhomboid",
  "Fan-Shape",
  "Window",
  "Double terminated point",
  "Obelisk",
  "Intaglio",
  "Cameo",
  "Baroque",
  "Cylinder",
  "Tumbled",
  "Tile",
];

const gemstoneCutShapes = [
  "Round",
  "Oval",
  "Square",
  "Rectangular",
  "Navette",
  "Teardrop",
  "Heart",
  "Kite",
  "Rhombus",
  "Triangular",
  "Hexagonal",
  "Octagonal",
  "Shield",
  "Bullet",
  "Droplet",
  "Tapered",
  "Half Moon",
  "Geometric",
  "Free",
  "Trapezoid",
  "Triangle",
  "Pentagonal",
  "Circular Sector",
  "Irregular",
];

const gemstoneCutStyles = [
  "Brilliant",
  "Cabochon",
  "Carved",
  "Polished",
  "Rose",
  "Mixed",
  "Faceted",
  "Modified Brilliant",
  "Step",
  "Checkerboard",
  "Laser",
  "Scissor",
  "Bead",
  "Concave",
  "Barion",
  "Polished drilled",
  "Unpolished",
  "Engraved",
  "Natural drilled",
  "Natural",
];

const colorNames = [
  "Black",
  "White",
  "Gray",
  "Red",
  "Green",
  "Blue",
  "Pink",
  "Yellow",
  "Orange",
  "Purple",
  "Champagne",
  "Brown",
  "Teal",
  "Iridescent",
];

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

function HeaderFilters({ isExpanded, setIsExpanded }) {
  return (
    <ListItem component="section" disablePadding>
      <ListItemButton
        sx={{ height: 56 }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <ListItemIcon sx={{ minWidth: isExpanded ? 56 : 0 }}>
          <Tune color="primary" />
        </ListItemIcon>

        {isExpanded && (
          <ListItemText
            primary="Filters"
            primaryTypographyProps={{
              color: "primary",
              fontWeight: "medium",
              variant: "body2",
            }}
          />
        )}
      </ListItemButton>
      <Tooltip title="Settings">
        <>
          <IconButton
            size="large"
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{
              "& svg": {
                color: "rgba(255,255,255,0.8)",
                transition: "0.2s",
                transform: "translateX(0) rotate(0)",
              },
              "&:hover, &:focus": {
                bgcolor: "unset",
                "& svg:last-of-type": {
                  right: 0,
                  opacity: 1,
                },
              },
              "&::after": {
                content: '""',
                position: "absolute",
                height: "80%",
                display: "block",
                left: 0,
                width: "1px",
                bgcolor: "divider",
              },
            }}
          >
            <ArrowBackIosNew />
          </IconButton>
        </>
      </Tooltip>
    </ListItem>
  );
}

const initialStateFilters = {
  name: "",
  color: "",
  cutName: "",
  cutShape: "",
  cutStyle: "",
  isMulticolor: false,
  primaryColor: "",
  height: [0, 0],
  width: [0, 0],
  depth: [0, 0],
  girdle: [0, 0],
  table: [0, 0],
  crown: [0, 0],
  pavilion_depth: [0, 0],
  pavilion_average_angle: [0, 0],
  weight_amount: [0, 0],
  weight_unit: "ct",
};

function SidebarSearch() {
  const [openGeneral, setOpenGeneral] = useState(true);
  const [openColor, setOpenColor] = useState(false);
  const [openCut, setOpenCut] = useState(false);
  const [openSize, setOpenSize] = useState(false);
  const [openWeight, setOpenWeight] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [filters, setFilters] = useState(initialStateFilters);

  const handleFilterChange = (field) => (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFilters({ ...filters, [field]: value });
  };

  const handleAutocompleteChange = (field) => (event, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const handleRangeChange = (field) => (event, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const buildPrompt = () => {
    const parts = [];

    if (filters?.name) {
      parts.push(filters.name);
    }

    if (filters?.cutName?.length) {
      parts.push(
        `cut ${filters.cutName.length === 0 ? "name" : "names"} ${
          filters.cutName
        }`
      );
    }

    if (filters?.cutShape?.length) {
      parts.push(
        `${filters.cutShape.length === 0 ? "shape" : "shapes"} ${
          filters.cutShape
        }`
      );
    }

    if (filters?.cutStyle?.length) {
      parts.push(
        `${filters.cutStyle.length === 0 ? "style" : "styles"} ${
          filters.cutStyle
        }`
      );
    }

    if (filters?.primaryColor) {
      parts.push(
        `color ${filters.primaryColor}`
      );
    }

    if (filters.isMulticolor) {
      parts.push("and is multicolor");
    }

    if (filters.height?.[0] !== 0 || filters.height?.[1] !== 0) {
      parts.push(`height ${filters.height[0]}-${filters.height[1]}mm`);
    }

    if (filters.width?.[0] !== 0 || filters.width?.[1] !== 0) {
      parts.push(`width ${filters.width[0]}-${filters.width[1]}mm`);
    }

    if (filters.depth?.[0] !== 0 || filters.depth?.[1] !== 0) {
      parts.push(`depth ${filters.depth[0]}-${filters.depth[1]}mm`);
    }

    if (filters.girdle?.[0] !== 0 || filters.girdle?.[1] !== 0) {
      parts.push(`girdle ${filters.girdle[0]}-${filters.girdle[1]}mm`);
    }

    if (filters.table?.[0] !== 0 || filters.table?.[1] !== 0) {
      parts.push(`table ${filters.table[0]}-${filters.table[1]}mm`);
    }

    if (filters.crown?.[0] !== 0 || filters.crown?.[1] !== 0) {
      parts.push(`crown ${filters.crown[0]}-${filters.crown[1]}mm`);
    }

    if (
      filters.pavilion_depth?.[0] !== 0 ||
      filters.pavilion_depth?.[1] !== 0
    ) {
      parts.push(
        `pavilion depth ${filters.pavilion_depth[0]}-${filters.pavilion_depth[1]}mm`
      );
    }

    if (
      filters.pavilion_average_angle?.[0] !== 0 ||
      filters.pavilion_average_angle?.[1] !== 0
    ) {
      parts.push(
        `pavilion average angle ${filters.pavilion_average_angle[0]}-${filters.pavilion_average_angle[1]}mm`
      );
    }

    if (filters.weight_amount?.[0] !== 0 || filters.weight_amount?.[1] !== 0) {
      parts.push(
        `weight ${filters.weight_amount[0]}-${filters.weight_amount[1]}${filters.weight_unit}`
      );
    }

    if (parts.length > 0) {
      const prompt = `Search all ${parts.join(" ")}`;
      return prompt;
    } else {
      return "all";
    }
  };

  const parsePrompt = (query) => {
    const updatedFilters = initialStateFilters;

    if (query?.name?.$regex) {
      updatedFilters.name = gemstoneNames.find(
        (name) => name.toLowerCase() === query.name.$regex.toLowerCase()
      );
    }

    if (query?.["properties.color.name"]?.$regex) {
      updatedFilters.primaryColor = colorNames.find(
        (color) => color.toLowerCase() === query["properties.color.name"].$regex.toLowerCase()
      );
    }

    if (query?.["properties.cut.name"]?.$regex) {
      updatedFilters.cutName = gemstoneCutNames.find(
        (name) => name.toLowerCase() === query["properties.cut.name"].$regex.toLowerCase()
      );
    }

    if (query?.["properties.cut.shape"]?.$regex) {
      updatedFilters.cutShape = gemstoneCutShapes.find(
        (name) => name.toLowerCase() === query["properties.cut.shape"].$regex.toLowerCase()
      );
    }

    if (query?.["properties.cut.style"]?.$regex) {
      updatedFilters.cutStyle = gemstoneCutStyles.find(
        (name) => name.toLowerCase() === query["properties.cut.style"].$regex.toLowerCase()
      );
    }

    if (query?.["properties.size.height"]) {
      updatedFilters.height = [
        query["properties.size.height"].$gte || 0,
        query["properties.size.height"].$lte || 0,
      ];
    }

    if (query?.["properties.size.width"]) {
      updatedFilters.width = [
        query["properties.size.width"].$gte || 0,
        query["properties.size.width"].$lte || 0,
      ];
    }

    if (query?.["properties.size.depth"]) {
      updatedFilters.depth = [
        query["properties.size.depth"].$gte || 0,
        query["properties.size.depth"].$lte || 0,
      ];
    }

    if (query?.["properties.size.girdle"]) {
      updatedFilters.girdle = [
        query["properties.size.girdle"].$gte || 0,
        query["properties.size.girdle"].$lte || 0,
      ];
    }

    if (query?.["properties.size.table"]) {
      updatedFilters.table = [
        query["properties.size.table"].$gte || 0,
        query["properties.size.table"].$lte || 0,
      ];
    }

    if (query?.["properties.size.crown"]) {
      updatedFilters.crown = [
        query["properties.size.crown"].$gte || 0,
        query["properties.size.crown"].$lte || 0,
      ];
    }

    if (query?.["properties.size.pavilion_depth"]) {
      updatedFilters.pavilion_depth = [
        query["properties.size.pavilion_depth"].$gte || 0,
        query["properties.size.pavilion_depth"].$lte || 0,
      ];
    }

    if (query?.["properties.size.pavilion_average_angle"]) {
      updatedFilters.pavilion_average_angle = [
        query["properties.size.pavilion_average_angle"].$gte || 0,
        query["properties.size.pavilion_average_angle"].$lte || 0,
      ];
    }

    if (query?.["properties.weight.amount"]) {
      updatedFilters.weight_amount = [
        query["properties.weight.amount"].$gte || 0,
        query["properties.weight.amount"].$lte || 0,
      ];
    }

    return updatedFilters;
  };

  const onPromptChange = (prompt) => {
    var event = new CustomEvent("prompt-changed", {
      detail: { prompt },
    });
    window.dispatchEvent(event);
  };

  const applyFiltersOnClick = () => {
    const prompt = buildPrompt();

    onPromptChange(prompt);
  };

  const clearFiltersOnClick = () => {
    setFilters({
      ...initialStateFilters,
      name: "",
      color: "",
      cutName: "",
      cutShape: "",
      cutStyle: "",
      isMulticolor: false,
      primaryColor: "",
      height: [0, 0],
      width: [0, 0],
      depth: [0, 0],
      girdle: [0, 0],
      table: [0, 0],
      crown: [0, 0],
      pavilion_depth: [0, 0],
      pavilion_average_angle: [0, 0],
      weight_amount: [0, 0],
      weight_unit: "ct",
    });
  };

  useEffect(() => {
    window.addEventListener("data-loaded", (event) => {
      if (!event?.detail?.query) {
        return;
      }

      const parsedFilters = parsePrompt(event?.detail?.query);
      setFilters(parsedFilters);
    });
  }, []);

  return (
    <Box
      sx={{ borderRadius: 0 }}
      className={`d-none d-md-flex  p-0 ${
        isExpanded
          ? "sidebar-expanded col-3 w-max-330px"
          : "sidebar-collapsed col-1 w-max-100px"
      }`}
    >
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: "dark",
            primary: { main: "rgb(102, 157, 246)" },
            background: { paper: "#051e34" },
          },
        })}
      >
        <Paper
          elevation={0}
          className={`sidebar w-100`}
          sx={{ borderRadius: 0 }}
        >
          <Collapse
            in={isExpanded}
            timeout="auto"
            unmountOnExit
            className="d-block"
          >
            <FireNav component="nav" disablePadding>
              <HeaderFilters
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
              <Divider />
              {/* General filters */}
              <Box
                sx={{
                  bgcolor: openGeneral ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: openGeneral ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpenGeneral(!openGeneral)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: openGeneral ? 0 : 2.5,
                    "&:hover, &:focus": {
                      "& svg": { opacity: openGeneral ? 1 : 0 },
                    },
                  }}
                >
                  <ListItemText
                    primary="General"
                    secondary={openGeneral ? null : "Gems"}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      mb: "2px",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: openGeneral
                        ? "rgba(0,0,0,0)"
                        : "rgba(255,255,255,0.5)",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: openGeneral ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {openGeneral && (
                  <Box sx={{ px: 3, py: 2 }}>
                    <Autocomplete
                      options={gemstoneNames || null}
                      getOptionLabel={(option) => option}
                      value={filters.name || ""}
                      onChange={handleAutocompleteChange("name")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Gemstone"
                          placeholder="Select gemstone"
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>
              <Divider />
              {/* Color filters */}
              <Box
                sx={{
                  bgcolor: openColor ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: openColor ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpenColor(!openColor)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: openColor ? 0 : 2.5,
                    "&:hover, &:focus": {
                      "& svg": { opacity: openColor ? 1 : 0 },
                    },
                  }}
                >
                  <ListItemText
                    primary="Color"
                    secondary={
                      openColor ? null : "Primary and secondary colors"
                    }
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      mb: "2px",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: openColor
                        ? "rgba(0,0,0,0)"
                        : "rgba(255,255,255,0.5)",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: openColor ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {openColor && (
                  <Box sx={{ px: 3, py: 2 }}>
                    <Box sx={{ mb: 2 }}>
                      <Autocomplete
                        options={colorNames || null}
                        getOptionLabel={(option) => option}
                        value={filters.primaryColor || ""}
                        onChange={handleAutocompleteChange("primaryColor")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Primary color"
                            placeholder="Select a color"
                          />
                        )}
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters.isMulticolor}
                          onChange={handleFilterChange("isMulticolor")}
                          name="isMulticolor"
                          color="primary"
                        />
                      }
                      label="Is Multicolor"
                    />
                  </Box>
                )}
              </Box>
              <Divider />
              {/* Cut filter */}
              <Box
                sx={{
                  bgcolor: openCut ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: openCut ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpenCut(!openCut)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: openCut ? 0 : 2.5,
                    "&:hover, &:focus": {
                      "& svg": { opacity: openCut ? 1 : 0 },
                    },
                  }}
                >
                  <ListItemText
                    primary="Cut"
                    secondary={openCut ? null : "Name, shape, and style"}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      mb: "2px",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: openCut
                        ? "rgba(0,0,0,0)"
                        : "rgba(255,255,255,0.5)",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: openCut ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {openCut && (
                  <Box sx={{ px: 3, py: 2 }}>
                    <Autocomplete
                      options={gemstoneCutNames || null}
                      getOptionLabel={(option) => option}
                      value={filters.cutName}
                      onChange={handleAutocompleteChange("cutName")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Cut Name"
                          placeholder="Select cut names"
                        />
                      )}
                    />
                    <Autocomplete
                      options={gemstoneCutShapes || null}
                      getOptionLabel={(option) => option}
                      value={filters.cutShape}
                      onChange={handleAutocompleteChange("cutShape")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Shape"
                          placeholder="Select shapes"
                        />
                      )}
                    />
                    <Autocomplete
                      options={gemstoneCutStyles || null}
                      getOptionLabel={(option) => option}
                      value={filters.cutStyle}
                      onChange={handleAutocompleteChange("cutStyle")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Style"
                          placeholder="Select styles"
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>
              <Divider />
              {/* Size */}
              <Box
                sx={{
                  bgcolor: openSize ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: openSize ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpenSize(!openSize)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: openSize ? 0 : 2.5,
                    "&:hover, &:focus": {
                      "& svg": { opacity: openSize ? 1 : 0 },
                    },
                  }}
                >
                  <ListItemText
                    primary="Size"
                    secondary={
                      openSize
                        ? null
                        : "Height, width, depth, girdle, table, crown, pavilion depth, and pavilion average angle"
                    }
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      mb: "2px",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: openSize
                        ? "rgba(0,0,0,0)"
                        : "rgba(255,255,255,0.5)",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: openSize ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {openSize && (
                  <Box sx={{ px: 3, py: 2 }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Height (mm)</Typography>
                      <Slider
                        value={filters.height}
                        onChange={handleRangeChange("height")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Width (mm)</Typography>
                      <Slider
                        value={filters.width}
                        onChange={handleRangeChange("width")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Depth (mm)</Typography>
                      <Slider
                        value={filters.depth}
                        onChange={handleRangeChange("depth")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Girdle (mm)</Typography>
                      <Slider
                        value={filters.girdle}
                        onChange={handleRangeChange("girdle")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Table (mm)</Typography>
                      <Slider
                        value={filters.table}
                        onChange={handleRangeChange("table")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Crown (mm)</Typography>
                      <Slider
                        value={filters.crown}
                        onChange={handleRangeChange("crown")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Pavilion Depth (mm)</Typography>
                      <Slider
                        value={filters.pavilion_depth}
                        onChange={handleRangeChange("pavilion_depth")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>
                        Pavilion Average Angle
                      </Typography>
                      <Slider
                        value={filters.pavilion_average_angle}
                        onChange={handleRangeChange("pavilion_average_angle")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={90}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              <Divider />
              {/* Weight filters */}
              <Box
                sx={{
                  bgcolor: openWeight ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: openWeight ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpenWeight(!openWeight)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: openWeight ? 0 : 2.5,
                    "&:hover, &:focus": {
                      "& svg": { opacity: openWeight ? 1 : 0 },
                    },
                  }}
                >
                  <ListItemText
                    primary="Weight"
                    secondary={openWeight ? null : "Amount and unit"}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      mb: "2px",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: openWeight
                        ? "rgba(0,0,0,0)"
                        : "rgba(255,255,255,0.5)",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: openWeight ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {openWeight && (
                  <Box sx={{ px: 3, py: 2 }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Amount</Typography>
                      <Slider
                        value={filters.weight_amount}
                        onChange={handleRangeChange("weight_amount")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={500}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>Unit</InputLabel>
                        <Select
                          value={filters.weight_unit}
                          onChange={handleFilterChange("weight_unit")}
                        >
                          <MenuItem value="ct">Carats (ct)</MenuItem>
                          <MenuItem value="g">Grams (g)</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                )}
              </Box>
              <Divider />
              {/* Apply button */}
              <Box className="d-flex">
                <Button
                  variant="outlined"
                  size="small"
                  className="mx-auto mt-4 mb-5"
                  sx={{
                    backgroundColor: "#dcdcdc",
                    color: "#051e34",
                    borderColor: "#051e34",
                    "&:hover": {
                      backgroundColor: "#051e34",
                      color: "#dcdcdc",
                    },
                  }}
                  onClick={clearFiltersOnClick}
                >
                  Clear filters
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className="mx-auto mt-4 mb-5"
                  sx={{
                    backgroundColor: "#dcdcdc",
                    color: "#051e34",
                    borderColor: "#051e34",
                    "&:hover": {
                      backgroundColor: "#051e34",
                      color: "#dcdcdc",
                    },
                  }}
                  onClick={applyFiltersOnClick}
                >
                  Apply filters
                </Button>
              </Box>
            </FireNav>
          </Collapse>

          {!isExpanded && (
            <HeaderFilters
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            />
          )}
        </Paper>
      </ThemeProvider>
    </Box>
  );
}

export default SidebarSearch;
