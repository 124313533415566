import React from "react";
import { Outlet } from "react-router-dom";

const LayoutMaintenance = () => {
  return (
    <>
      <div className="mt-5 mb-5">
        <div className="container">
          {/* Start Content */}
          <Outlet />
          {/* End Content */}
        </div>
      </div>
    </>
  );
};

export default LayoutMaintenance;