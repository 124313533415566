import React from "react";

function NavbarSearchLanding(props) {
  return (
    <nav
      className="navbar navbar-expand-lg menu_one menu_logo_change"
      id="sticky"
    >
      <div className="container">
        <a className="navbar-brand sticky_logo" href="/">
          <img
            src="/assets/img/logo.png"
            srcSet="/assets/img/logo-2x.png 2x"
            alt="logo"
          />
          <img
            src="/assets/img/logo.png"
            srcSet="/assets/img/logo-2x.png 2x"
            alt="logo"
          />
        </a>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="menu_toggle">
            <span className="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </span>
            <span className="hamburger-cross">
              <span></span>
              <span></span>
            </span>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav menu ml-auto dk_menu">
            <li className="nav-item dropdown submenu mega_menu tab-demo">
              <a
                href="https://etrune.com"
                className="nav-link dropdown-toggle"
                target="_blank"
              >
                Company
              </a>
            </li>
            <li className="nav-item dropdown submenu">
              <a
                className="nav-link dropdown-toggle"
                href="https://etrune.com/collections/all"
                target="_blank"
              >
                Engagement rings
              </a>
            </li>
          </ul>
          <button
            className="nav_btn icon-btn round-btn"
            onClick={() => {
              const message = `Hello! I need some general assistance. Could you help me, please?`;
              window.open(
                `https://wa.me/19295886646?text=${encodeURIComponent(message)}`,
                "_blank"
              );
            }}
          >
            <i className="icon_headphones"></i>Support
          </button>
        </div>
      </div>
    </nav>
  );
}

export default NavbarSearchLanding;
