import React from "react";

function NavbarSearch(props) {
  return (
    <>
      <nav className="navbar navbar-expand-lg menu_one display_none">
        <div className="container custom_container">
          <a className="navbar-brand sticky_logo" href="/">
            <img
              src="/assets/img/logo-w.png"
              srcSet="/assets/img/logo-w2x.png 2x"
              alt="logo"
            />
            <img
              src="/assets/img/logo.png"
              srcSet="/assets/img/logo-2x.png 2x"
              alt="logo"
            />
          </a>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="menu_toggle">
              <span className="hamburger">
                <span></span>
                <span></span>
                <span></span>
              </span>
              <span className="hamburger-cross">
                <span></span>
                <span></span>
              </span>
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav menu ml-auto">
              <li className="nav-item dropdown submenu">
                <a
                  className="nav-link dropdown-toggle"
                  href="https://etrune.com/"
                >
                  Company
                </a>
              </li>
              <li className="nav-item dropdown submenu mega_menu tab-demo">
                <a
                  href="https://etrune.com/collections/all"
                  target="_blank"
                  className="nav-link dropdown-toggle"
                >
                  Engagement rings
                </a>
              </li>
            </ul>
            <button
              className="nav_btn"
              onClick={() => {
                const message = `Hello! I need some general assistance. Could you help me, please?`;
                window.open(
                  `https://wa.me/19295886646?text=${encodeURIComponent(
                    message
                  )}`,
                  "_blank"
                );
              }}
            >
              <i className="icon_headphones"></i> Support
            </button>
          </div>
        </div>
      </nav>
      <div className="mobile_main_menu" id="sticky">
        <div className="container">
          <div className="mobile_menu_left">
            <button type="button" className="navbar-toggler mobile_menu_btn">
              <span className="menu_toggle ">
                <span className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </span>
            </button>
            <a className="sticky_logo" href="/">
              <img
                src="/assets/img/logo-w.png"
                srcSet="/assets/img/logo-w2x.png 2x"
                alt="logo"
              />
              <img
                src="/assets/img/logo.png"
                srcSet="/assets/img/logo-2x.png 2x"
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavbarSearch;
