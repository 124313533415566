import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import NavbarSearchLanding from "../components/navbar/NavbarSearchLanding";
import FooterSearchLanding from "../components/footer/FooterSearchLanding";

const LayoutAdmin = () => {
  const [pageName, setPageName] = useState('');

  return (
    <>
      <div className="body_wrapper">

        <NavbarSearchLanding pageName={pageName} />

        <Outlet context={{ setPageName }} />

        <FooterSearchLanding />
        
      </div >
    </>
  );
};

export default LayoutAdmin;