import BaseApi from '../base/apiBase.service'

export default class ItemService extends BaseApi {
  constructor (args) {
    super(args)

    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_BACKEND_URL,
      get: '/inventory/item/'
    }
  }

  async getByParameters(data) {
    return super.getByParameters(data);
  }
}