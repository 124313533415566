import React, { useEffect, useState, Suspense } from "react";
import { useOutletContext } from "react-router-dom";

import { ItemService } from "../../services/index";
import { SnapData, OnPageLoaded, Alert } from "@link-loom/ui-sdk";
import GppGoodIcon from "@mui/icons-material/GppGood";
import HistoryIcon from "@mui/icons-material/History";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PublicIcon from "@mui/icons-material/Public";
import VerifiedIcon from "@mui/icons-material/Verified";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BlockIcon from "@mui/icons-material/Block";
import {
  FindInPageTwoTone,
  HistoryEduTwoTone,
  TempleBuddhistTwoTone,
  AutoStoriesTwoTone,
  AutoAwesomeTwoTone,
  ContrastTwoTone,
  StarsTwoTone,
  HelpOutline,
} from "@mui/icons-material";
import "@link-loom/ui-sdk/dist/styles.css";

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
} from "@mui/material";

function loadZoom() {
  window.setTimeout(() => {
    if (window.$(".zoom").length) {
      window.$(".zoom").magnify({
        afterLoad: function () {
          console.log("Magnification powers activated!");
        },
      });
    }
  }, 200);
}

function dataLoaded() {
  var event = new Event("page-loaded");
  window.dispatchEvent(event);
}

const ICON_MAP = {
  FindInPageTwoTone: FindInPageTwoTone,
  HistoryEduTwoTone: HistoryEduTwoTone,
  TempleBuddhistTwoTone: TempleBuddhistTwoTone,
  AutoStoriesTwoTone: AutoStoriesTwoTone,
  AutoAwesomeTwoTone: AutoAwesomeTwoTone,
  ContrastTwoTone: ContrastTwoTone,
  StarsTwoTone: StarsTwoTone,
};

const getIconComponent = (iconName) => {
  return ICON_MAP[iconName] || HelpOutline;
};

const IconDisplay = ({ iconName, ...props }) => {
  const IconComponent = getIconComponent(iconName);
  return <IconComponent {...props} />;
};

function generateFullDescription(entity) {
  const details = [
    entity?.name && `Gemstone Name: ${entity.name}`,
    entity?.metadata?.report && `Report ID: ${entity.metadata.report}`,

    entity?.properties?.weight?.trade_weight &&
      `Weight: ${entity.properties.weight.trade_weight}`,
    entity?.properties?.size?.trade_measure &&
      `Size: ${entity.properties.size.trade_measure}`,
    entity?.properties?.cut?.name &&
      `Cut: ${entity.properties.cut.name} (${entity.properties.cut.style})`,
    entity?.properties?.color?.primary_hex &&
      `Primary Color Hex: ${entity.properties.color.primary_hex}`,
    entity?.properties?.color?.is_multicolor
      ? "Multicolor: Yes"
      : "Multicolor: No",

    // Gemological Composition
    entity?.properties?.gemological_composition?.family &&
      `Gem Family: ${entity.properties.gemological_composition.family}`,
    entity?.properties?.gemological_composition?.species &&
      `Species: ${entity.properties.gemological_composition.species}`,
    entity?.properties?.gemological_composition?.variety &&
      `Variety: ${entity.properties.gemological_composition.variety}`,
    entity?.properties?.gemological_composition?.subvariety &&
      `Subvariety: ${entity.properties.gemological_composition.subvariety}`,
    entity?.properties?.gemological_composition?.chemical_formula &&
      `Chemical Formula: ${entity.properties.gemological_composition.chemical_formula}`,
    entity?.properties?.gemological_composition?.is_lab_grown
      ? "Lab Grown: Yes"
      : "Lab Grown: No",
    entity?.properties?.gemological_composition?.hardness_mohs_scale &&
      `Hardness (Mohs): ${entity.properties.gemological_composition.hardness_mohs_scale}`,
    entity?.properties?.gemological_composition?.refractive_index &&
      `Refractive Index: ${entity.properties.gemological_composition.refractive_index}`,
    entity?.properties?.gemological_composition?.specific_gravity &&
      `Specific Gravity: ${entity.properties.gemological_composition.specific_gravity}`,
    entity?.properties?.gemological_composition?.origin &&
      `Origin: ${entity.properties.gemological_composition.origin}`,

    // Laboratory Report
    entity?.properties?.laboratory_report?.certification_id &&
      `Certification ID: ${entity.properties.laboratory_report.certification_id}`,
    entity?.properties?.laboratory_report?.certifying_authority &&
      `Certifying Authority: ${entity.properties.laboratory_report.certifying_authority}`,
    entity?.properties?.laboratory_report?.issue_date &&
      `Issue Date: ${entity.properties.laboratory_report.issue_date}`,
    entity?.properties?.laboratory_report?.comments &&
      `Comments: ${entity.properties.laboratory_report.comments}`,

    // Scores
    entity?.properties?.score?.symmetry &&
      `Symmetry Score: ${entity.properties.score.symmetry}`,
    entity?.properties?.score?.clarity &&
      `Clarity Score: ${entity.properties.score.clarity}`,
    entity?.properties?.score?.cut &&
      `Cut Score: ${entity.properties.score.cut}`,
    entity?.properties?.score?.damage &&
      `Damage Score: ${entity.properties.score.damage}`,
    entity?.properties?.score?.treatment &&
      `Treatment Score: ${entity.properties.score.treatment}`,

    // Location and Availability
    entity?.properties?.location?.box &&
      `Box Location: ${entity.properties.location.box}`,
    entity?.properties?.location?.slot &&
      `Slot Location: ${entity.properties.location.slot}`,
    entity?.quantity && `Available Pieces: ${entity.quantity}`,

    // Pricing Information
    entity?.properties?.price?.current_price &&
      `Current Price: $${entity.properties.price.current_price}`,
    entity?.properties?.price?.acquisition_cost &&
      `Acquisition Cost: $${entity.properties.price.acquisition_cost}`,
  ].filter(Boolean);

  return details.join(". ") + ".";
}

const alertConfig = {
  typeIcon: "info",
  title: "Feature Unavailable",
  description: "This option is available only for selected gemstones.",
  confirmButtonText: "OK",
  showCancelButton: false,
};

function SearchDetails() {
  const { setPageName, setEntityLayout } = useOutletContext();
  const [entity, setEntity] = useState({});
  const [confirm, setConfirm] = useState(false);
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const reportType = params.id ? "id" : "report";

  const renderTableRow = ({ label, value, type, entity }) => {
    const renderContent = () => {
      if (type === "button") {
        return (
          <a
            href={value}
            className="btn btn-dark"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>
        );
      }
      if (type === "image") {
        return renderThirdPartyLogo(entity);
      }

      return (
        <>
          <span>{value}</span>
        </>
      );
    };

    return (
      <TableRow>
        <TableCell component="th" scope="row" className="col-4 text-truncate">
          {label}
        </TableCell>
        <TableCell align="right">{renderContent()}</TableCell>
      </TableRow>
    );
  };

  const renderThirdPartyLogo = (entity) => {
    return (
      <a
        href={entity?.properties?.laboratory_report?.report_url}
        target="_blank"
      >
        {entity?.properties?.laboratory_report?.certifying_authority.toLowerCase() ===
          "gia" && (
          <img
            src="/assets/img/certification-logo/gia-logo.png"
            alt="certified by GIA"
            width="80"
          />
        )}
        {entity?.properties?.laboratory_report?.certifying_authority.toLowerCase() ===
          "cdtec" && (
          <img
            src="/assets/img/certification-logo/cdtec-logo.jpg"
            alt="certified by CDTEC GemLab"
            width="80"
          />
        )}
      </a>
    );
  };

  useEffect(() => {
    setPageName("item-0001");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const entityService = new ItemService();
      const entityResponse = await entityService.getByParameters({
        queryselector: reportType,
        search: params.id || params.report || "",
      });

      console.log(entityResponse);

      if (
        !entityResponse?.success ||
        !entityResponse?.result ||
        !entityResponse?.result?.items?.length
      ) {
        window.location.assign(`/not-found?id=${params.id}`);
        return;
      }

      setEntity(entityResponse?.result?.items?.[0] || {});
      setEntityLayout(entityResponse.result || {});
      
      dataLoaded();

      loadZoom();
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <>
      <section className="w-100">
        <section className="row d-flex flex-fill">
          {/* Main info */}
          <article className="col-12 mt-4 d-flex flex-wrap">
            {/* Images */}
            <article className="col-12 col-lg-6 mt-4 d-flex">
              <div className="d-flex flex-column flex-md-row w-100">
                {/* Sidebar for Thumbnails */}
                <div className="d-flex flex-column align-items-start mr-5">
                  {entity?.media?.map((item, index) => (
                    <img
                      key={index}
                      src={item.url || "/assets/img/preview-unavailable.jpg"}
                      alt={"Thumbnail " + (index + 1)}
                      className="mb-2 border opacity-07"
                      height="60"
                      width="60"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        /* Add functionality to update the main image */
                      }}
                    />
                  ))}
                </div>

                {/* Main Image */}
                <img
                  className="my-auto zoom d-block mx-auto gemstone-img"
                  src={
                    entity?.media?.[0]?.url ||
                    "/assets/img/preview-unavailable.jpg"
                  }
                  alt={"Etrune Gemstone" + entity?.name}
                />
              </div>
            </article>

            {/* Primary info */}
            <article className="col-12 col-lg-6 my-4">
              <div className="w-100 w-md-75">
                <header>
                  {/* Name */}
                  <h2 className="text-etrune">
                    {entity?.name} {entity?.properties?.cut?.name} -{" "}
                    {entity?.properties?.weight?.trade_weight}.
                  </h2>
                </header>

                {/* ID */}
                <article className="text-muted mb-0">
                  <small className="d-flex">
                    <span className="mr-2">Item ID:</span>
                    <SnapData
                      id="name"
                      data={entity?.id}
                      alignment="left"
                      icon={{
                        className:
                          "btn py-0 px-1 text-black-50 no-shadow small-85",
                      }}
                      onEdit={() => {}}
                    />
                  </small>
                </article>

                {/* Report */}
                <article className="text-muted mb-0">
                  <small className="d-flex">
                    <span className="mr-2">Report:</span>
                    <SnapData
                      id="name"
                      data={entity?.metadata?.report}
                      alignment="left"
                      icon={{
                        className:
                          "btn py-0 px-1 text-black-50 no-shadow small-85",
                      }}
                      onEdit={() => {}}
                    />
                  </small>
                </article>

                {/* Price */}
                <article className="d-flex align-items-end align-content-end">
                  <h4 className="text-etrune">
                    <span>
                      {entity?.properties?.price?.current_price
                        ? `$${new Intl.NumberFormat("en-US").format(
                            entity.properties.price.current_price
                          )}`
                        : "—"}
                    </span>
                  </h4>
                  <small className="ml-2 text-etrune">
                    {entity?.properties?.price?.asset_symbol || ""}
                  </small>
                </article>

                {/* Availibility */}
                <article>
                  <small className="text-muted">
                    {entity?.quantity > 0 ? (
                      <>
                        <GppGoodIcon
                          className="text-success mr-1"
                          style={{ fontSize: "1.2rem" }}
                        />
                        <span className="mr-1">
                          {entity?.status?.name === "active"
                            ? "Available"
                            : entity?.status?.title}
                        </span>
                      </>
                    ) : (
                      <>
                        <BlockIcon
                          className="text-danger mr-1"
                          style={{ fontSize: "1.2rem" }}
                        />
                        <span className="mr-1">Unavailable</span>
                      </>
                    )}
                    /<span className="ml-1">{entity?.quantity || 0} Pcs</span>
                  </small>
                </article>

                {/* Worldwide shipping */}
                <article>
                  <small className="text-muted">
                    <PublicIcon
                      className="text-dark-blue  mr-1"
                      style={{ fontSize: "1.2rem" }}
                    />
                    <Tooltip
                      title={
                        <p
                          style={{ lineHeight: "1.2", display: "block" }}
                          className="pt-2"
                        >
                          Enjoy complimentary worldwide shipping with secure
                          tracking, ensuring your gemstone reaches you wherever
                          you are.
                        </p>
                      }
                      placement="top"
                      arrow
                    >
                      <button className="btn btn-sm btn-link-2  pl-0 text-left">
                        Complimentary Shipping Worldwide
                      </button>
                    </Tooltip>
                  </small>
                </article>

                {/* Certified */}
                <article>
                  <small className="text-muted">
                    <VerifiedIcon
                      className="text-dark-blue  mr-1"
                      style={{ fontSize: "1.2rem" }}
                    />
                    <Tooltip
                      title={
                        <p
                          style={{ lineHeight: "1.2", display: "block" }}
                          className="pt-2"
                        >
                          This certification guarantees the gemstone's
                          authenticity, quality, and source, recognized by
                          leading gemological authorities.
                        </p>
                      }
                      placement="top"
                      arrow
                    >
                      <button className="btn btn-sm btn-link-2  pl-0 text-left">
                        Certified Authentic Gemstone
                      </button>
                    </Tooltip>
                  </small>
                </article>

                {/* Third-party certification */}
                {entity?.properties?.laboratory_report?.certification_id && (
                  <article className="d-flex flex-row mt-2">
                    {renderThirdPartyLogo(entity)}
                  </article>
                )}

                {/* Call to action */}
                <article className="d-flex flex-column">
                  <button
                    className="btn btn-block btn-cta btn-lg mt-4"
                    onClick={() => {
                      const message = `I'm interested in building custom jewelry with gemstone with report #${entity?.metadata?.report}. Could you assist me with the next steps?`;
                      window.open(
                        `https://wa.me/19295886646?text=${encodeURIComponent(
                          message
                        )}`,
                        "_blank"
                      );
                    }}
                  >
                    Build jewelry with this gemstone
                  </button>

                  <button
                    className="btn btn-block btn-outline-secondary btn-sm mt-3"
                    onClick={() => {
                      const message = `I would like to inquire about purchasing gemstone report #${entity?.metadata?.report} as a loose stone. Could you provide more details?`;
                      window.open(
                        `https://wa.me/19295886646?text=${encodeURIComponent(
                          message
                        )}`,
                        "_blank"
                      );
                    }}
                  >
                    Purchase loose
                  </button>
                  <section className="d-flex mt-3">
                    {/* Heritage */}
                    <button
                      className="btn btn-outline-secondary btn-sm align-items-center flex-fill text-center mr-2"
                      onClick={() => {
                        setConfirm(true);
                      }}
                    >
                      <HistoryIcon className="mr-2" /> <span>Heritage</span>
                    </button>

                    {/* Valuation Trend */}
                    <button
                      className="btn btn-outline-secondary btn-sm align-items-center flex-fill text-center mx-2"
                      onClick={() => {
                        setConfirm(true);
                      }}
                    >
                      <TrendingUpIcon className="mr-2" />{" "}
                      <span>Valuation Trend</span>
                    </button>

                    {/* Legacy Exchange */}
                    <button
                      className="btn btn-outline-secondary btn-sm align-items-center flex-fill text-center ml-2"
                      onClick={() => {
                        setConfirm(true);
                      }}
                    >
                      <CurrencyExchangeIcon className="mr-2" />{" "}
                      <span>Exchange</span>
                    </button>
                  </section>
                </article>
              </div>
            </article>
          </article>

          {/* Trust indicators */}
          <section className="bg-white col-12 mt-0 pt-5 text-center d-none">
            <article className="text-center mt-4">
              <h6 className="text-etrune">
                Exclusivity, Authenticity & Legacy
              </h6>
              <hr className="mb-2 mt-3 w-25" />
              <ul className="list-unstyled">
                <li>
                  <small>Responsibly sourced, authenticated gems</small>
                </li>
                <li>
                  <small>Secure global shipping at no cost</small>
                </li>
                <li>
                  <small>14-Day effortless returns*</small>
                </li>
                <li>
                  <small>Promoting a circular gem economy</small>
                </li>
              </ul>
            </article>
          </section>

          {/* Description */}
          <section className="bg-light col-12 mt-4 pt-5 text-center">
            <article className="col-12 col-md-7 mx-auto">
              <h5 className="text-etrune font-weight-bold">Description</h5>
              <p className="text-muted">
                <small>
                  {(entity.description || generateFullDescription(entity))
                    .replace(/\\n/g, "\n")
                    .split("\n")
                    .map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </small>
              </p>
              <section className="d-flex flex-wrap justify-content-center pt-3">
                {entity?.properties?.references?.map((reference) => (
                  <a
                    href={reference.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-light"
                    key={reference.custom_id}
                  >
                    <article className="d-flex flex-column">
                      <IconDisplay
                        iconName={reference.icon}
                        className="text-dark-blue mx-4"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="text-muted mb-0">
                        <small>{reference.text}</small>
                      </p>
                    </article>
                  </a>
                ))}
              </section>
            </article>
          </section>

          {/* Data Content */}
          <section className="col-12 pt-4 pb-4 bg-light">
            <div className="col-12 col-md-8 mx-auto pl-3 pl-md-0">
              <hr className="w-25" />
              <h4 className="text-etrune text-center mb-4 mt-5">Details</h4>

              {/* Accordion for Details */}
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Overview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      {renderTableRow({
                        label: "Weight",
                        value:
                          entity?.properties?.weight?.trade_weight || "N/A",
                      })}
                      {renderTableRow({
                        label: "Size",
                        value: entity?.properties?.size?.trade_measure || "N/A",
                      })}
                      {renderTableRow({
                        label: "Cut",
                        value:
                          `${entity?.properties?.cut?.name} ${entity?.properties?.cut?.style}` ||
                          "N/A",
                      })}
                      {renderTableRow({
                        label: "Availability",
                        value: `${entity?.quantity || 0} Pcs Available`,
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              {/* Cut Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: "38%", flexShrink: 0 }}>
                    Cut
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Detailed analysis of cut quality and facet precision.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      {renderTableRow({
                        label: "Name",
                        value: entity?.properties?.cut?.name || "N/A",
                      })}
                      {renderTableRow({
                        label: "Style",
                        value: entity?.properties?.cut?.style || "N/A",
                      })}
                      {renderTableRow({
                        label: "Facet Count",
                        value: entity?.properties?.cut?.facet_count || "N/A",
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              {/* Color Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: "38%", flexShrink: 0 }}>
                    Color
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Comprehensive color analysis, capturing primary and
                    secondary hues.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      {renderTableRow({
                        label: "Primary Hex",
                        value: entity?.properties?.color?.primary_hex || "N/A",
                      })}
                      {renderTableRow({
                        label: "Secondary Hex",
                        value:
                          entity?.properties?.color?.secondary_hexadecimal ||
                          "N/A",
                      })}
                      {renderTableRow({
                        label: "Multicolor",
                        value: entity?.properties?.color?.is_multicolor
                          ? "Yes"
                          : "No",
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              {/* Size Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: "38%", flexShrink: 0 }}>
                    Size
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Accurate measurements detailing gem dimensions and girdle
                    attributes.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      {renderTableRow({
                        label: "Dimensions",
                        value: entity?.properties?.size?.trade_measure || "N/A",
                      })}
                      {renderTableRow({
                        label: "Girdle",
                        value: entity?.properties?.size?.girdle || "N/A",
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              {/* Weight Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: "38%", flexShrink: 0 }}>
                    Weight
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Precise measurement of gem weight for trade and
                    classification.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      {renderTableRow({
                        label: "Amount",
                        value:
                          `${entity?.properties?.weight?.amount} ${entity?.properties?.weight?.unit}` ||
                          "N/A",
                      })}
                      {renderTableRow({
                        label: "Trade Weight",
                        value:
                          entity?.properties?.weight?.trade_weight || "N/A",
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              {/* Gemological Composition Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: "38%", flexShrink: 0 }}>
                    Gemological Composition
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Technical breakdown of core gemological properties.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      {renderTableRow({
                        label: "Family",
                        value:
                          entity?.properties?.gemological_composition?.family ||
                          "N/A",
                      })}
                      {renderTableRow({
                        label: "Species",
                        value:
                          entity?.properties?.gemological_composition
                            ?.species || "N/A",
                      })}
                      {renderTableRow({
                        label: "Variety",
                        value:
                          entity?.properties?.gemological_composition
                            ?.variety || "N/A",
                      })}
                      {renderTableRow({
                        label: "Subvariety",
                        value:
                          entity?.properties?.gemological_composition
                            ?.subvariety || "N/A",
                      })}
                      {renderTableRow({
                        label: "Chemical Formula",
                        value:
                          entity?.properties?.gemological_composition
                            ?.chemical_formula || "N/A",
                      })}
                      {renderTableRow({
                        label: "Origin",
                        value:
                          entity?.properties?.gemological_composition?.origin ||
                          "N/A",
                      })}
                      {renderTableRow({
                        label: "Treatments",
                        value:
                          entity?.properties?.gemological_composition
                            ?.treatments || "N/A",
                      })}
                      {renderTableRow({
                        label: "Hardness (Mohs Scale)",
                        value:
                          entity?.properties?.gemological_composition
                            ?.hardness_mohs_scale || "N/A",
                      })}
                      {renderTableRow({
                        label: "Refractive Index",
                        value:
                          entity?.properties?.gemological_composition
                            ?.refractive_index || "N/A",
                      })}
                      {renderTableRow({
                        label: "Specific Gravity",
                        value:
                          entity?.properties?.gemological_composition
                            ?.specific_gravity || "N/A",
                      })}
                      {renderTableRow({
                        label: "Inclusions",
                        value:
                          entity?.properties?.gemological_composition
                            ?.inclusions || "N/A",
                      })}
                      {renderTableRow({
                        label: "Dispersion",
                        value:
                          entity?.properties?.gemological_composition
                            ?.dispersion || "N/A",
                      })}
                      {renderTableRow({
                        label: "Birefringence",
                        value:
                          entity?.properties?.gemological_composition
                            ?.birefringence || "N/A",
                      })}
                      {renderTableRow({
                        label: "Lab Grown",
                        value: entity?.properties?.gemological_composition
                          ?.is_lab_grown
                          ? "Yes"
                          : "No",
                      })}
                      {renderTableRow({
                        label: "Fluorescence",
                        value:
                          entity?.properties?.gemological_composition
                            ?.fluorescence?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Optical Effects",
                        value:
                          entity?.properties?.gemological_composition
                            ?.optical_effects?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Luster",
                        value:
                          entity?.properties?.gemological_composition?.luster
                            ?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Phenomena",
                        value:
                          entity?.properties?.gemological_composition?.phenomena
                            ?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Crystal System",
                        value:
                          entity?.properties?.gemological_composition
                            ?.crystal_system?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Tenacity",
                        value:
                          entity?.properties?.gemological_composition?.tenacity
                            ?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Fracture",
                        value:
                          entity?.properties?.gemological_composition?.fracture
                            ?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Cleavage",
                        value:
                          entity?.properties?.gemological_composition?.cleavage
                            ?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Pleochroism",
                        value:
                          entity?.properties?.gemological_composition
                            ?.pleochroism?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Color Zoning",
                        value:
                          entity?.properties?.gemological_composition
                            ?.color_zoning?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Thermal Conductivity",
                        value:
                          entity?.properties?.gemological_composition
                            ?.thermal_conductivity?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Phosphorescence",
                        value:
                          entity?.properties?.gemological_composition
                            ?.phosphorescence?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Durability",
                        value:
                          entity?.properties?.gemological_composition
                            ?.durability?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Specific Inclusions",
                        value:
                          entity?.properties?.gemological_composition
                            ?.specific_inclusions?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Double Refraction Index",
                        value:
                          entity?.properties?.gemological_composition
                            ?.double_refraction_index?.title || "N/A",
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              {/* Laboratory Report Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: "38%", flexShrink: 0 }}>
                    Third-Party Laboratory Report
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Independent certification to reinforce our gemological
                    standards.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      {renderTableRow({
                        label: "Certifying Authority",
                        value:
                          entity?.properties?.laboratory_report
                            ?.certifying_authority || "N/A",
                        type: "image",
                        entity,
                      })}
                      {renderTableRow({
                        label: "Certification ID",
                        value:
                          entity?.properties?.laboratory_report
                            ?.certification_id || "N/A",
                      })}
                      {renderTableRow({
                        label: "Certification Issue Date",
                        value:
                          entity?.properties?.laboratory_report?.issue_date ||
                          "N/A",
                      })}
                      {renderTableRow({
                        label: "Certification Comments",
                        value: (
                          entity?.properties?.laboratory_report?.comments ||
                          "N/A"
                        )
                          .replace(/\\n/g, "\n")
                          .split("\n")
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          )),
                      })}
                      {renderTableRow({
                        label: "Certification URL",
                        value:
                          entity?.properties?.laboratory_report?.report_url ||
                          "N/A",
                        type: "button",
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              {/* Etrune Score Section */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="etrune-score-content"
                  id="etrune-score-header"
                >
                  <Typography sx={{ width: "38%", flexShrink: 0 }}>
                    Etrune Score
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Our exclusive 0-9 grading system. Score reflects key
                    attributes.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      {renderTableRow({
                        label: "Symmetry",
                        value: entity?.properties?.score?.symmetry || "N/A",
                      })}
                      {renderTableRow({
                        label: "Color",
                        value: entity?.properties?.score?.color || "N/A",
                      })}
                      {renderTableRow({
                        label: "Clarity",
                        value: entity?.properties?.score?.clarity || "N/A",
                      })}
                      {renderTableRow({
                        label: "Cut",
                        value: entity?.properties?.score?.cut || "N/A",
                      })}
                      {renderTableRow({
                        label: "Uniqueness",
                        value: entity?.properties?.score?.uniqueness || "N/A",
                      })}
                      {renderTableRow({
                        label: "Damage",
                        value: entity?.properties?.score?.damage || "N/A",
                      })}
                      {renderTableRow({
                        label: "Treatment",
                        value: entity?.properties?.score?.treatment || "N/A",
                      })}
                      {renderTableRow({
                        label: "Negative Score",
                        value:
                          entity?.properties?.score?.negative_score || "N/A",
                      })}
                      {renderTableRow({
                        label: "Positive Score",
                        value:
                          entity?.properties?.score?.positive_score || "N/A",
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              {/* Availability Section */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: "38%", flexShrink: 0 }}>
                    Availability
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Current stock status and available quantity.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      {renderTableRow({
                        label: "Status",
                        value: entity?.status?.title || "N/A",
                      })}
                      {renderTableRow({
                        label: "Quantity",
                        value: `${entity?.quantity || 0} Pcs Available`,
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </div>
          </section>

          {/* Call to action 2 */}
          <section className="bg-cta-2 col-12 d-flex py-5">
            <article className="w-100 w-md-75 d-flex flex-column flex-md-row justify-content-between align-items-center mx-auto">
              {/* Title and Subtitle */}
              <div className="col-10 col-md-8 text-left">
                <h5 className="text-etrune font-weight-bold">
                  Find the Gemstone That Speaks to You
                </h5>
                <p className="text-etrune">
                  <small>
                    Our experts are dedicated to making each gemstone a part of
                    your story. From sourcing to selection, we guide you in
                    finding the piece that resonates with your vision.
                  </small>
                </p>
              </div>

              {/* Button */}
              <div>
                <button
                  className="btn btn-cta"
                  onClick={() =>
                    window.open(
                      "https://wa.me/19295886646?text=I'm%20interested%20in%20finding%20the%20perfect%20gemstone%20for%20a%20commitment.",
                      "_blank"
                    )
                  }
                >
                  Connect with an Expert Today
                </button>
              </div>
            </article>
          </section>
        </section>
      </section>

      {confirm === true && (
        <Alert config={alertConfig} setConfirm={setConfirm} />
      )}
      <OnPageLoaded />
    </>
  );
}

export default SearchDetails;
