import React from "react";
import { useAuth } from "../../hooks/useAuth.hook";

function NavbarMobileSearch() {
  return (
    <div className="side_menu menu-opened">
      <div className="mobile_menu_header">
        <div className="close_nav">
          <i className="arrow_left"></i>
          <i className="icon_close"></i>
        </div>
        <div className="mobile_logo">
          <a href="/">
            <img src="/assets/img/logo.png" alt="logo" />
          </a>
        </div>
      </div>
      <div className="mobile_nav_wrapper">
        <nav className="mobile_nav_top">
          <ul className="navbar-nav menu ml-auto">
            <li className="nav-item">
              <a href="/" className="nav-link">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a href="https://etrune.com/" className="nav-link">
                Company
              </a>
            </li>
            <li className="nav-item">
              <a href="https://etrune.com/collections/all" className="nav-link">
                Engagement rings
              </a>
            </li>

            <li className="nav-item">
              <button
                className="nav-link"
                onClick={() => {
                  const message = `Hello! I need some general assistance. Could you help me, please?`;
                  window.open(
                    `https://wa.me/19295886646?text=${encodeURIComponent(
                      message
                    )}`,
                    "_blank"
                  );
                }}
              >
                Support
              </button>
            </li>
          </ul>
        </nav>
        <div className="mobile_nav_bottom">
          <aside className="doc_left_sidebarlist">
            <div className="scroll">
              <ul className="list-unstyled nav-sidebar">
                <li className="nav-item">
                  <a href="/" className="nav-link">
                    <img src="assets/img/side-nav/home.png" alt="" />
                    Aside navigation -- obsolete
                  </a>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
}

export default NavbarMobileSearch;
