import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { OnPageLoaded } from "@link-loom/ui-sdk";
import CallToAction2 from "../../components/shared/CallToAction2";

async function navigateToQuery({ query }) {
  if (query.includes("item-")) {
    window.location.assign(`/search/details?id=${query}`);
  } else if (Number.isInteger(parseFloat(query))) {
    window.location.assign(`/search/details?report=${query}`);
  } else {
    window.location.assign(`/search?q=${query || "all"}`);
  }
}

function queryInputFocus() {
  const input = document.querySelector("#query-input");
  input.focus();
}

function SearchLanding() {
  const location = useLocation();
  const [query, setQuery] = useState("");
  const [queryType, setQueryType] = useState("report-id");
  const [selectedFromList, setSelectedFromList] = useState(false);

  const textOptions = [
    { title: "Amethyst gemstones" },
    { title: "Round garnet with round cut" },
    { title: "Sapphire with marquise cut" },
    { title: "Onyx with tile cut" },
    { title: "Emerald with pear cut" },
    { title: "Ruby with round cut" },
  ];
  const reportOptions = [
    { title: "3943" },
    { title: "4157" },
    { title: "4150" },
    { title: "4105" },
    { title: "3783" },
  ];

  const handleSubmit = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    navigateToQuery({
      query,
    });
  };

  useEffect(() => {
    if (location.pathname.includes("report")) {
      setQueryType("report-id");
    } else {
      setQueryType("query");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (Number.isInteger(parseFloat(query))) {
      setQueryType("report-id");
    } else {
      setQueryType("query");
    }
  }, [query]);

  useEffect(() => {
    if (selectedFromList) {
      handleSubmit();
    }
  }, [selectedFromList]);

  return (
    <>
      <section className="kbDoc-banner-support">
        <div className="banner-content text-center">
          <div className="banner-content-wrapper">
            <h1 className="banner-title wow fadeInUp text-etrune mb-4">
              ÊTRUNE ID
            </h1>
            <div
              className="banner-search-form-wrapper wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                autoComplete="off"
                className="banner-search-form"
              >
                {queryType === "report-id" && (
                  <section className="d-flex justify-content-center">
                    <div className="ml-3 my-auto px-2">
                      <span>Report:</span>
                    </div>
                  </section>
                )}
                <div className="search-input">
                  <Autocomplete
                    freeSolo
                    id="query-input"
                    disableClearable
                    options={
                      queryType === "report-id"
                        ? reportOptions.map((option) => option.title)
                        : textOptions.map((option) => option.title)
                    }
                    onChange={(event, value) => {
                      navigateToQuery({
                        query: value,
                      });
                    }}
                    onInputChange={(event, value) => {
                      setQuery(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        slotprops={{
                          input: {
                            ...params.InputProps,
                            type: "search",
                            disableUnderline: true,
                          },
                        }}
                      />
                    )}
                  />
                </div>

                <button type="submit">
                  <i className="icon_search"></i>
                </button>
              </form>
              <div className="mx-auto my-3 d-none">
                <button
                  className={
                    "mx-2 " +
                    (queryType === "query" ? "action_btn" : "doc_border_btn")
                  }
                  onClick={(_) => {
                    setQueryType("query");
                    queryInputFocus();
                  }}
                >
                  Explore
                </button>
                <button
                  className={
                    "mx-2 " +
                    (queryType === "report-id"
                      ? "action_btn"
                      : "doc_border_btn")
                  }
                  onClick={(_) => {
                    setQueryType("report-id");
                    queryInputFocus();
                  }}
                >
                  Report
                </button>
              </div>
            </div>
          </div>
        </div>

        <ul className="people-image wow fadeIn" data-wow-delay="0.7s">
          <li id="1">
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn1.jpg"
              alt="people"
            />
          </li>
          <li id="2" className="d-none d-md-block">
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn2.jpg"
              alt="people"
            />
          </li>
          <li id="3">
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn3.jpg"
              alt="people"
            />
          </li>
          <li id="4">
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn4.jpg"
              alt="people"
            />
          </li>
          <li id="5" className="d-none d-md-block">
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn5.jpg"
              alt="people"
            />
          </li>
          <li id="6">
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn6.jpg"
              alt="people"
            />
          </li>
          <li id="7">
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn7.jpg"
              alt="people"
            />
          </li>
          <li id="8" className="d-none d-md-block">
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn8.jpg"
              alt="people"
            />
          </li>
          <li id="9" className="d-none d-md-block">
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn9.jpg"
              alt="people"
            />
          </li>
          <li id="10" className="d-none d-md-block">
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn10.jpg"
              alt="people"
            />
          </li>
        </ul>
        <ul className="partical-animation wow fadeIn" data-wow-delay="0.9s">
          <li className="partical">
            <img
              data-parallax='{"x": -180, "y": 80, "rotateY":2000}'
              src="/assets/img/home_support/bnp1.png"
              alt="partical"
            />
          </li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical">
            <img
              src="/assets/img/home_support/bnp2.png"
              data-parallax='{"x": -250, "y": -160, "rotateZ":200}'
              alt="partical"
            />
          </li>
        </ul>
        <div className="bottom-shape">
          <img
            src="/assets/img/home_support/banner-bottom-shape.png"
            alt="banner"
          />
        </div>
      </section>

      {/* Call to action 1 */}
      <section className="communities">
        <div className="container">
          <section className="section_title title-img text-center">
            <h2 className="title-lg wow fadeInUp text-etrune mb-0">
              Eternal Elegance, Verified
            </h2>
            <p>Authenticity, quality, and legacy—all in one place.</p>
          </section>

          <div className="row justify-content-between">
            <article className="col-lg-3 col-md-6">
              <div className="wow fadeInUp" data-wow-delay="0.4s">
                <div className="icon-container">
                  <img
                    src="/assets/img/home_support/section1.jpg"
                    alt="community"
                    className="cta-image-banner mb-3"
                  />
                </div>
                <div className="community-content">
                  <h5 className="text-etrune font-weight-normal mb-0">
                    Design Your Legacy
                  </h5>
                  <p className="mb-2">
                    Your ring, your story, brought to your life.
                  </p>
                  <a
                    href="https://etrune.com/pages/our-process"
                    className="details-link btn btn-cta details-link"
                  >
                    Discover
                  </a>
                </div>
              </div>
            </article>

            <article className="col-lg-3 col-md-6">
              <div className="wow fadeInUp" data-wow-delay="0.6s">
                <div className="icon-container">
                  <img
                    src="/assets/img/home_support/section2.jpg"
                    alt="community"
                    className="cta-image-banner mb-3"
                  />
                </div>
                <div className="community-content">
                  <h5 className="text-etrune font-weight-normal mb-0">
                    Proof in Every Detail
                  </h5>
                  <p className="mb-2">
                    Unmatched authenticity, forever secured.
                  </p>
                  <a
                    href="https://etrune.com/pages/our-materials"
                    className="details-link btn btn-cta details-link"
                  >
                    Discover
                  </a>
                </div>
              </div>
            </article>

            <article className="col-lg-3 col-md-6">
              <div className="wow fadeInUp" data-wow-delay="0.8s">
                <div className="icon-container">
                  <img
                    src="/assets/img/home_support/section3.jpg"
                    alt="community"
                    className="cta-image-banner mb-3"
                  />
                </div>
                <div className="community-content">
                  <h5 className="text-etrune font-weight-normal mb-0">
                    A Treasure Beyond Time
                  </h5>
                  <p className="mb-2">
                    Care, renew, and celebrate your masterpiece.
                  </p>
                  <a
                    href="https://etrune.com/pages/product-care"
                    className="details-link btn btn-cta details-link"
                  >
                    Discover
                  </a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>

      <CallToAction2 />
      <OnPageLoaded />
    </>
  );
}

export default SearchLanding;
