import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import {
  SearchLanding,
  SearchResults,
  SearchDetails,
  MaintenanceGeneral
} from './pages/index';

import {
  LayoutSearchLanding,
  LayoutSearch,
  LayoutMaintenance
} from './layouts/index';

function App () {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<LayoutSearchLanding />}>
            <Route index element={<SearchLanding />} />
            <Route path="report" element={<SearchLanding />} />
            <Route path="explore" element={<SearchLanding />} />
          </Route>

          <Route path="/search" element={<LayoutSearch />}>
            <Route index element={<SearchResults />} />
            <Route path="details" element={<SearchDetails />} />
          </Route>

          <Route path="*" element={<LayoutMaintenance />} >
            <Route path="*" element={<MaintenanceGeneral />} />
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
