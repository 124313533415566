import React from "react";

import "@link-loom/ui-sdk/dist/styles.css";


function CallToAction2() {
  

  return (
    <>
          {/* Call to action 2 */}
          <section className="bg-cta-2 col-12 d-flex py-5">
            <article className="w-100 w-md-75 d-flex flex-column flex-md-row justify-content-between align-items-center mx-auto">
              {/* Title and Subtitle */}
              <div className="col-10 col-md-8 text-left">
                <h5 className="text-etrune font-weight-bold">
                  Find the Gemstone That Speaks to You
                </h5>
                <p className="text-etrune">
                  <small>
                    Our experts are dedicated to making each gemstone a part of
                    your story. From sourcing to selection, we guide you in
                    finding the piece that resonates with your vision.
                  </small>
                </p>
              </div>

              {/* Button */}
              <div>
                <button
                  className="btn btn-cta"
                  onClick={() =>
                    window.open(
                      "https://wa.me/19295886646?text=I'm%20interested%20in%20finding%20the%20perfect%20gemstone%20for%20a%20commitment.",
                      "_blank"
                    )
                  }
                >
                  Connect with an Expert Today
                </button>
              </div>
            </article>
          </section>
    </>
  );
}

export default CallToAction2;
